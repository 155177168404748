<template>
  <div class="">
    <ul class="tablist">
      <li class="tabmenu" @click="goPageByName('point')">포인트 적립내역</li>
      <li class="tabmenu current" @click="goPageByName('pointuse')">포인트 전환신청</li>
      <li class="tabmenu" @click="goPageByName('pointuselist')">포인트 전환내역</li>
    </ul>

    <div class="leftWrap">
      <div class="moneyread">
        <h3>{{$t('front.cash.moneyCheckList')}}</h3>
        <ul>
          <li>{{$t('front.point.moneyPoint')}}</li>
          <li>{{$t('front.point.limitPoint')}}</li>
          <li>{{$t('front.cash.moneyCheckListD')}}</li>
        </ul>
      </div>

      <div class="moneyinfoappli">
        <div class="moneyinfo">
          <h3 class="applih">{{$t('front.point.changePoint')}}</h3>
          <p class="applip">{{$t('front.point.changePointA')}} <span class="blc">1,000</span>P {{$t('front.point.changePointB')}}</p>
        </div>

        <div class="appliinputwrap">
           <ul class="appliinput">
             <li>{{$t('front.point.leftPoint')}}</li>
             <li><span class="blc numb">{{thousand(currentPoint.toString())}}</span>P</li>
           </ul>
           <ul class="appliinput">
             <li>{{$t('front.point.expectPoint')}}</li>
             <li><span class="numb">{{thousand(changePoint.toString())}}</span>P</li>
           </ul>
           <ul class="appliinput">
             <li>{{$t('front.point.expectToPoint')}}</li>
             <li><span class="numb">{{thousand(remainPoint.toString())}}</span>P</li>
           </ul>
           <ul class="appliinput">
             <li>{{$t('front.point.pointInput')}}</li>
             <li><input type="text" pattern="[0-9.,]+" class="numb" :placeholder="$t('front.cash.inputNumber')" v-model="changePoint"></li>
           </ul>
           <ul class="moneybtnwrap ml150">
             <li class="one" @click="setPoint(10000)"><a>1{{$t('front.cash.manwon')}}</a></li>
             <li class="one" @click="setPoint(30000)"><a>3{{$t('front.cash.manwon')}}</a></li>
             <li class="one" @click="setPoint(50000)"><a>5{{$t('front.cash.manwon')}}</a></li>
             <li class="two" @click="setPoint(100000)"><a>10{{$t('front.cash.manwon')}}</a></li>
             <li class="two" @click="setPoint(300000)"><a>30{{$t('front.cash.manwon')}}</a></li>
             <li class="two" @click="setPoint(500000)"><a>50{{$t('front.cash.manwon')}}</a></li>
             <li class="four" @click="setPoint(0)"><a>{{$t('front.cash.correct')}}</a></li>
             <li class="thr" @click="setPoint('all')"><a>{{$t('front.cash.all')}}</a></li>
           </ul>
          <div class="applibtns">
            <a @click="onSubmit">{{$t('front.cash.moneyCheckListL')}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { setChangePoint } from '@/api/point'
import { thousand } from '@/libs/utils'

export default {
  name: 'pointUse',
  watch: {
    changePoint () {
      const tempcgPoint1 = Number(this.currentPoint)
      const tempcgPoint2 = Number(this.changePoint.replace(/,/g, ''))
      if (tempcgPoint1 < tempcgPoint2) {
        this.onCheck('front.point.enoughChangePoint')
        this.changePoint = ''
        return false
      } else {
        this.remainPoint = this.currentPoint - Number(this.changePoint.replace(/,/g, ''))

        const parts = this.changePoint.split('.')
        const v = parts[0].replace(/\D/g, '')
        const dec = parts[1]
        const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
        // use this for numeric calculations
        console.log('number for calculations: ', calcNum)
        let n = new Intl.NumberFormat('en-EN').format(v)
        n = dec !== undefined ? n + '.' + dec : n
        this.changePoint = n
      }
    }
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  created () {
    this.currentPoint = this.userData.pointAmt || 0
  },
  data () {
    return {
      isPopupOpen: false,
      currentPoint: '',
      changePoint: '',
      remainPoint: 0,
      limitMinPoint: 10000
    }
  },
  methods: {
    setPoint (value) {
      let point = Number(this.changePoint.replace(/,/g, ''))
      if (value === 'all') {
        point = this.currentPoint
      } else {
        if (value) {
          point += value
        } else {
          point = ''
        }
      }

      console.log(this.currentPoint)

      this.changePoint = thousand(point)
    },
    async onSubmit () {
      if (this.changePoint) {
        if (this.limitMinPoint > this.changePoint) {
          this.onCheck('front.point.limitPoint')
          return false
        }

        const confirm = await this.onConfirm('front.point.confirm')
        if (confirm) {
          const param = {
            pointAmt: Number(this.changePoint.replace(/,/g, ''))
          }

          setChangePoint(param).then(async response => {
            const result = response.data
            if (result.resultCode === '0') {
              const success = await this.onCheck('front.point.success')
              if (success) {
                location.reload()
              }
            }
          })
        }
      } else {
        await this.onCheck('front.point.check')
      }
    }
  }
}
</script>
<style scoped>

</style>

<style src="@/styles/striNew.css"></style>
